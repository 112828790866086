<template>
  <div class="home-banner">
    <BaseCarousel auto-play :sliders="sliders" />
  </div>
</template>
<script>
import { ref } from 'vue'
import { findAd } from '@/api/home'
export default {
  name: 'HomeBanner',
  setup () {
    // 获取轮播图数据
    const sliders = ref([])
    findAd("homeCarousel").then(data => {
      sliders.value = data.data.list
    })
    return { sliders }
  }
}
</script>
<style scoped lang="less">
.home-banner {
  width: 1240px;
  height: 500px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 98
}
// 覆盖样式
.base-carousel {
  :deep(.carousel-btn.prev){
    // left: 270px;
  }
  :deep(.carousel-indicator) {
    // padding-left: 250px;
  }
}
</style>
