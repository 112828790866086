<template>
  <div class="home-new" style="padding-top: 500px;">
    <HomePanel title="前后端功能组件化" sub-title="基于现有模板，组件，结合需求快速开发网站及其系统原型">
      <div ref="target" style="position: relative;height: 426px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <ul  class="goods-list">
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/1.png" alt="">
                <p class="name ellipsis">前后端功能组件化</p>
                <!-- <p class="price">&yen;</p> -->
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/2.png" alt="">
                <p class="name ellipsis">提高研发效率</p>
                <!-- <p class="price">&yen;</p> -->
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/3.png" alt="">
                <p class="name ellipsis">二开灵活度90%</p>
                <!-- <p class="price">&yen;</p> -->
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/4.png" alt="">
                <p class="name ellipsis">项目周期缩短1/6</p>
                <!-- <p class="price">&yen;</p> -->
              </RouterLink>
            </li>
          </ul>
        
        </Transition>
      </div>
    </HomePanel>
  </div>
</template>
<script>
import HomePanel from './home-panel'
export default {
  name: 'HomeNew',
  components: { HomePanel },
  setup () {
  }
}
</script>
<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 406px;
  li {
    width: 306px;
    height: 406px;
    background: #fff;
    .hoverShadow();
    img {
      width: 306px;
      height: 306px;
    }
    p {
      font-size: 22px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
}
</style>
