<template>
  <HomePanel title="保障整体业" sub-title="提供金融行业尖端等级灾备方案">
    <template v-slot:right>
      <a @click="toggle(-1)" :class="{disabled:index===0}" href="javascript:;" class="iconfont icon-angle-left prev"></a>
      <a @click="toggle(1)" :class="{disabled:index===1}" href="javascript:;" class="iconfont icon-angle-right next"></a>
    </template>
    <div ref="target" class="box">
      <Transition name="fade">
        <ul v-if="brands.length" class="list" :style="{transform:`translateX(${-index*1240}px)`}">
          <li v-for="item in brands" :key="item.id">
            <RouterLink to="/">
              <img :src="item.picture" alt="">
            </RouterLink>
          </li>
        </ul>
        <div v-else class="skeleton">
          <BaseSkeleton class="item" v-for="i in 5" :key="i" animated bg="#e4e4e4" width="240px" height="305px"/>
        </div>
      </Transition>
    </div>
  </HomePanel>
</template>

<script>
import { ref } from 'vue'
import HomePanel from './home-panel'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeBrand',
  components: { HomePanel },
  setup () {
    // 获取数据
    // const brands = ref([])
    // findBrand(10).then(data => {
    //   brands.value = data.result
    // })
    // 注意：useLazyData需要的是API函数，如果遇到要传参的情况，自己写函数再函数中调用API
    
    //const { target, result } = useLazyData(() => findBrand(10))
    const { target, result } = {
    "target": "操作成功",
    "result": [
        {
            "id": "1351770184914280450",
            "name": "硕华品质",
            "nameEn": "PHILIPS",
            "logo": "http://demo.hyxdsoft.com/img/web10001/11.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/11.jpg",
            "type": null,
            "desc": "good",
            "place": "中国江苏传智"
        },
        {
            "id": "1373956466142089218",
            "name": "WD永在",
            "nameEn": "wd",
            "logo": "http://demo.hyxdsoft.com/img/web10001/22.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/22.jpg",
            "type": null,
            "desc": "连接你我",
            "place": "中国江苏传智"
        },
        {
            "id": "1",
            "name": "咏汉定制",
            "nameEn": "jjzr",
            "logo": "http://demo.hyxdsoft.com/img/web10001/33.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/33.jpg",
            "type": null,
            "desc": "优秀的品牌，优秀的传扬",
            "place": "中国江苏传智"
        },
        {
            "id": "2",
            "name": "ICCUG",
            "nameEn": "iccug",
            "logo": "http://demo.hyxdsoft.com/img/web10001/44.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/44.jpg",
            "type": null,
            "desc": "知识就是力量",
            "place": "中国北京顺义"
        },
        {
            "id": "3",
            "name": "釉色美颜",
            "nameEn": "ysmy",
            "logo": "http://demo.hyxdsoft.com/img/web10001/55.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/55.jpg",
            "type": null,
            "desc": "学霸的魅力",
            "place": "中国北京顺义"
        },
        {
            "id": "4",
            "name": "绿荫",
            "nameEn": "ly",
            "logo": "http://demo.hyxdsoft.com/img/web10001/66.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/66.jpg",
            "type": null,
            "desc": "一寸光阴一寸金",
            "place": "中国北京顺义"
        },
        {
            "id": "4",
            "name": "绿荫",
            "nameEn": "ly",
            "logo": "http://demo.hyxdsoft.com/img/web10001/77.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/77.jpg",
            "type": null,
            "desc": "一寸光阴一寸金",
            "place": "中国北京顺义"
        },
        {
            "id": "4",
            "name": "绿荫",
            "nameEn": "ly",
            "logo": "http://demo.hyxdsoft.com/img/web10001/88.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/88.jpg",
            "type": null,
            "desc": "一寸光阴一寸金",
            "place": "中国北京顺义"
        },
        {
            "id": "4",
            "name": "绿荫",
            "nameEn": "ly",
            "logo": "http://demo.hyxdsoft.com/img/web10001/99.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/99.jpg",
            "type": null,
            "desc": "一寸光阴一寸金",
            "place": "中国北京顺义"
        },
        {
            "id": "4",
            "name": "绿荫",
            "nameEn": "ly",
            "logo": "http://demo.hyxdsoft.com/img/web10001/111.jpg",
            "picture": "http://demo.hyxdsoft.com/img/web10001/111.jpg",
            "type": null,
            "desc": "一寸光阴一寸金",
            "place": "中国北京顺义"
        }
    ]
}


    // 切换效果，前提只有 0 1 两页
    const index = ref(0)
    // 1. 点击上一页
    // 2. 点击下一页
    const toggle = (step) => {
      const newIndex = index.value + step
      if (newIndex < 0 || newIndex > 1) return
      index.value = newIndex
    }
    return { brands: result, toggle, index, target }
  }
}
</script>

<style scoped lang='less'>
.skeleton {
  width: 100%;
  display: flex;
  .item {
    margin-right: 10px;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
.home-panel {
  background:#f5f5f5
}
.iconfont {
  width: 20px;
  height: 20px;
  background: #ccc;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  background: @baseColor;
  &::before {
    font-size: 12px;
    position: relative;
    top: -2px
  }
  &.disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}
.box {
  display: flex;
  width: 100%;
  height: 345px;
  overflow: hidden;
  padding-bottom: 40px;
  .list {
    width: 200%;
    display: flex;
    transition: all 1s;
    li {
      margin-right: 10px;
      width: 240px;
      &:nth-child(5n) {
        margin-right: 0;
      }
      img {
        width: 240px;
        height: 305px;
      }
    }
  }
}
</style>
