<template>
  <!-- 首页入口 -->
  <div class="home-entry">

    <div class="container">
      <!-- 左侧分类 -->
      <!-- <HomeMenu /> -->
      <!-- <HomeCategory /> -->
      <!-- 轮播图 -->
       <HomeBanner /> 
    </div>
  </div>
  <HomeNew /> 
  <HomeBrand />
  <!-- 产品区块 -->
  <!-- <HomeProduct /> -->
  <!-- 最新专题 -->
  <HomeSpecial />
</template>
<script>
// import HomeCategory from './components/home-category'
import HomeBanner from './components/home-banner'
import HomeNew from './components/home-new'
import HomeBrand from './components/home-brand'
import HomeSpecial from './components/home-special'
export default {
  name: 'HomePage',
  components: {
    HomeBanner,
    HomeNew,
    HomeBrand,
    HomeSpecial
  }
}
</script>
<style scoped lang="less"></style>
